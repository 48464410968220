import { timeFormat } from "../common/utils/DateUtils";
import { getTags } from "../common/services/conversation-service";
import { useEffect, useState, useCallback } from "react";
import * as Sentry from "@sentry/react";

const ConversationTableComponent = ({
  conversationsTabledata = [],
  phoneNumberId,
}) => {
  const [tags, setTags] = useState([]);
  const [dropDownText, setDropDownText] = useState("Tags");

  const getTag = useCallback(() => {
    try {
      getTags(phoneNumberId)
        .then((tags) => {
          setTags(tags.response);
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          console.log(err);
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
      console.log(err);
    }
  }, [phoneNumberId]);

  useEffect(() => {
    getTag();
  }, [phoneNumberId, conversationsTabledata]);

  const updateTag = (tag) => {
    setDropDownText(tag);
  };

  const TagsDropdown = ({ tag }) => {
    return (
      <div>
        <span className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100">
          {tag.tag_name}
        </span>
      </div>
    );
  };

  const updateStyles = (style) => {
    if (style === "PENDING") {
      return "text-orange-300 absolute inset-0 bg-gray-100 border border-gray-200 opacity-50 rounded-2xl text-[#F2F4F7]";
    } else if (style === "IN_PROGRESS") {
      return "!bg-[#ECFDF3] absolute inset-0 border border-gray-200 opacity-50 rounded-2xl !text-[#008138]";
    } else if (style === "RESOLVED") {
      return "absolute inset-0 bg-gray-100 border border-gray-200 opacity-50 rounded-2xl text-[#F2F4F7]";
    }
  };

  return (
    <div>
      <div>
        <div className="py-4">
          <h3 className=" font-semibold text-base text-gray-700">
            Conversations
          </h3>
        </div>
        {/* <div className="flex flex-wrap justify-start w-full">
          <div className="flex flex-wrap py-2">
            <div className="w-full px-4">
              <div className="relative inline-flex flex-wrap w-full">
                <button
                  className="bg-[#F6F9FC] text-gray-700 active:bg-gray-100 font-semibold py-2 px-4 rounded-2xl inline-flex items-center  ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => openDropdown(event, "tags")}
                >
                  <span className="mr-4 text-gray-700 text-sm font-semibold">
                    {dropDownText}
                  </span>
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
                  </svg>
                </button>
                <div>
                  <div
                    className="hidden bg-white max-h-[180px] overflow-scroll text-base z-50 min-w-[12rem] float-left py-2 list-none text-left rounded shadow-lg mt-1"
                    id="tags"
                  >
                    {typeof tags === "object" &&
                      tags.map((tag, i) => {
                        return <TagsDropdown key={i} tag={tag} />;
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap py-2">
            <div className="w-full px-4">
              <div className="relative inline-flex flex-wrap w-full">
                <button
                  className="bg-[#F6F9FC] text-gray-700 active:bg-gray-100 font-semibold py-2 px-4 rounded-2xl inline-flex items-center  ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => openDropdown(event, "agents")}
                >
                  <span className="mr-4 text-gray-700 text-sm font-semibold">
                    Agents
                  </span>
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
                  </svg>
                </button>
                <div
                  className="hidden bg-white  text-base z-50 min-w-[12rem] float-left py-2 list-none text-left rounded shadow-lg mt-1"
                  id="agents"
                >
                  <a
                    href="#"
                    className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100"
                  >
                    Action
                  </a>
                  <a
                    href="#"
                    className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100"
                  >
                    Another action
                  </a>
                  <a
                    href="#"
                    className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100"
                  >
                    Something else here
                  </a>
                  <div className="h-0 my-2 border border-solid border-t-0 border-blueGray-800 opacity-25"></div>
                  <a
                    href="#"
                    className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100"
                  >
                    Seprated link
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap py-2">
            <div className="w-full px-4">
              <div className="relative inline-flex flex-wrap w-full">
                <button
                  className="bg-[#F6F9FC] text-gray-700 active:bg-gray-100 font-semibold py-2 px-4 rounded-2xl inline-flex items-center  ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => openDropdown(event, "status")}
                >
                  <span className="mr-4 text-gray-700 text-sm font-semibold">
                    Status
                  </span>
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
                  </svg>
                </button>
                <div
                  className="hidden bg-white  text-base z-50 min-w-[12rem] float-left py-2 list-none text-left rounded shadow-lg mt-1"
                  id="status"
                >
                  <span className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100">
                    Pending
                  </span>
                  <span className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100">
                    Resolved
                  </span>
                  <span className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100">
                    In progress
                  </span>
                  <div className="h-0 my-2 border border-solid border-t-0 border-blueGray-800 opacity-25"></div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div>
          <div className="pb-8">
            <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
              <div className="inline-block min-w-full rounded-lg overflow-hidden">
                <table className="min-w-full leading-normal">
                  <thead>
                    <tr>
                      {/* <th className="px-5 py-3 border-b text-left text-sm font-semibold text-gray-700 tracking-wider">
                        Name
                      </th> */}
                      <th className="px-5 py-3 border-b text-left text-sm font-semibold text-gray-700 tracking-wider">
                        Mobile Phone
                      </th>
                      <th className="px-5 py-3 border-b text-left text-sm font-semibold text-gray-700 tracking-wider">
                        Tags
                      </th>
                      {/* <th className="px-5 py-3 border-b text-left text-sm font-semibold text-gray-700 tracking-wider">
                        Agents
                      </th> */}
                      <th className="px-5 py-3 border-b text-left text-sm font-semibold text-gray-700 tracking-wider">
                        Status
                      </th>
                      <th className="px-5 py-3 border-b text-left text-sm font-semibold text-gray-700 tracking-wider">
                        Last seen
                      </th>
                    </tr>
                  </thead>
                  {conversationsTabledata &&
                    conversationsTabledata.length > 0 &&
                    conversationsTabledata.map((acc, i) => (
                      <tbody key={i}>
                        <tr>
                          {/* <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <div className="flex rounded-xl">
                              <div className="flex justify-center items-center">
                                <div className="flex justify-center items-center uppercase flex-shrink-0 w-10 bg-gray-100 rounded-full h-10">
                                  {getNameInitials(acc?.userDetails.userName)}
                                </div>
                                <div className="ml-6">
                                  <p className="text-gray-700 font-medium text-sm whitespace-no-wrap">
                                    {acc?.userDetails.userName}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </td> */}
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <p className="font-normal text-sm text-[#475467] whitespace-no-wrap">
                              {" "}
                              {/* {acc?.userDetails?.userPhonenumber} */}
                              {acc.channel.split("_")[1]}{" "}
                            </p>
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            {acc?.tags?.map((tags, i) => (
                              <div key={i}>
                                <div className="">
                                  <span className="relative inline-block px-3 py-2 font-semibold text-gray-600 leading-tight">
                                    <span
                                      aria-hidden
                                      className="absolute inset-0 bg-white border border-gray-200 opacity-50 rounded-lg"
                                    ></span>
                                    <span className="relative p-2">{tags}</span>
                                  </span>
                                </div>
                              </div>
                            ))}
                            {acc?.tags?.length > 2 ? (
                              <span className="py-2 font-normal text-sm !text-gray-600">
                                {" "}
                                + {acc?.tags?.length}{" "}
                              </span>
                            ) : (
                              ""
                            )}
                          </td>
                          {/* <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <div className="flex rounded-xl">
                              <div className="flex justify-center items-center">
                                <div className="flex justify-center items-center uppercase flex-shrink-0 w-10 bg-gray-100 rounded-full h-10">
                                  {getNameInitials(
                                    acc.agentDetails[0].agentName
                                  )}
                                </div>
                                <div className="ml-6">
                                  <p className="text-gray-700 font-medium text-sm whitespace-no-wrap">
                                    {acc.agentDetails[0].agentName}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </td> */}
                          <td className="pr-8 py-5 border-b border-gray-200 bg-white text-sm ">
                            <span className="relative inline-block px-3 py-2 leading-tight">
                              <span
                                aria-hidden
                                className={updateStyles(acc.status)}
                              ></span>
                              <span
                                className={
                                  acc?.status === "IN_PROGRESS"
                                    ? "relative p-2 text-xs !text-[#008138]"
                                    : "relative p-2 text-xs"
                                }
                              >
                                {" "}
                                {acc?.status === "IN_PROGRESS"
                                  ? "in progress"
                                  : acc?.status?.toLowerCase()}{" "}
                              </span>
                            </span>
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                            <p className="font-normal text-sm text-[#475467] whitespace-no-wrap">
                              {timeFormat(acc?.lastUpdated)}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                </table>
              </div>
              {/* <div className="w-full py-5 flex">
                <div className="pr-6">
                  <span className=" font-medium text-sm pr-3 "> Show </span>
                  <input
                    className="p-4 border border-gray-300 w-[47px] h-[40px] rounded-lg"
                    placeholder="13"
                    type="text"
                  />
                </div>
                <nav aria-label="Page navigation example">
                  <ul className="inline-flex items-center -space-x-px ">
                    <li>
                      <a
                        href="#"
                        className=" px-5 h-[40px] py-3 ml-0 leading-tight flex text-[#1D2939] bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700"
                      >
                        <svg
                          aria-hidden="true"
                          className="w-5 h-5"
                          fill="#98A2B3"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <span className="text-sm text-[#98A2B3]">Previous</span>
                      </a>
                    </li>
                    <li className="">
                      <span className="px-5 ml-0 h-[40px] py-3 leading-tight text-[#1D2939] bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 ">
                        1
                      </span>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="px-5 h-[40px] py-3 leading-tight text-[#1D2939] bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                      >
                        2
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        aria-current="page"
                        className="z-10 px-5 h-[40px] py-3 leading-tight text-[#1D2939] border border-blue-300 bg-[#F2F4F7] hover:bg-blue-100 hover:text-blue-700"
                      >
                        3
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="px-5 h-[40px] py-3 leading-tight text-[#1D2939] bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                      >
                        4
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="px-5 h-[40px] py-3 leading-tight text-[#1D2939] bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                      >
                        5
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="flex px-5 h-[40px] py-3 leading-tight text-[#1D2939] bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700"
                      >
                        <span className="text-sm">Next</span>
                        <svg
                          aria-hidden="true"
                          className="w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConversationTableComponent;
